<template>
<div class="card">
    <div class="card-body">
        <div class="">
            <h5 class="border-bottom pb-2">Address details</h5>
            <div class="row g-3">
                <div class="col-12">
                    <label class="form-label fw-bold">Street Address</label>
                    <div>{{item.address}}</div>
                </div>
                <div class="col-12 col-md-4">
                    <label class="form-label fw-bold">City</label>
                    <div>{{item.city}}</div>
                </div>
                <div class="col-6 col-md-4">
                    <label class="form-label fw-bold">state</label>
                    <div>{{item.state}}</div>
                </div>
                <div class="col-6 col-md-4">
                    <label class="form-label fw-bold">Zip code</label>
                    <div>{{item.zip_code}}</div>
                </div>
            </div>
        </div>            
    </div>
</div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            required: true
        }
    },
    computed:{
     
    },    
}
</script>

<style>

</style>