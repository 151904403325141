<template>
  <div>
    <div class="table-responsive">
        <table class="table table-nowrap mb-0">
            <thead class="table-light">
                <tr>
                <th scope="col">Id</th>
                <th scope="col">Date</th>
                <th scope="col">Price</th>
                <th scope="col">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, inIndex) in items" :key="inIndex">
                <td>#{{item.id}}</td>
                <td>{{$filters.date_time(item.payment_date, 1) }}</td>
                <td>${{$filters.money_format(item.amount)}}</td>
                <td>{{item.status}}</td>
                </tr>
                <tr v-if="!items.length">
                <td colspan="100%" class="text-center">Nothing to show</td>
                </tr>
            </tbody>
        </table>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        items:{
            default:()=>([])
        }
    }
}
</script>

<style>

</style>