<template>
  <div v-if="item" class="bd-callout small p-2 my-0 bd-callout-info">
    <p class="mb-1">Payment method: </p>
    <span v-if="item.type == 'card'">
        <span class="fw-bold"> <i class="bi bi-credit-card"></i> Card ending at ...{{item.option.last4}}</span></span>
    <span v-else class="fw-bold"> <i class="bi bi-credit-card"></i> {{item.type }}</span>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            default:null
        }
    }
}
</script>

<style>

</style>