
<template>
<div class="py-4">
  <div v-if="!isLoading" class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Appointment</h5>
      <div v-if="appointment">
        <div class="card mb-5">
          <div class="card-body">
            <div class="row gy-3 justify-content-between align-items-start">
              <div class="col-10 col-sm-8">
                <div class="friendly-id mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard">
                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                    <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                    </svg>
                    {{appointment.friendly_id}}
                </div>
              </div>
              <div class="col-2 col-sm-4 text-sm-end">
                <!-- <div class="dropdown no-arrow">
                  <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <button @click="setupEdit()" class="dropdown-item" type="button" >
                      Update payment method </button>
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>
            <div class="row gy-3">
              <div class="col-12">
                <strong class="me-1">Status:</strong>
                <span v-html="statusBadge(appointment.status)"> </span>
              </div>
              <div class="col-12">
                Total cost: <strong>${{$filters.money_format(appointment.total_cost)}} USD</strong>
              </div>
              <div class="col-6">
                Scheduled date: <strong> {{$filters.date(appointment.schedule_date)}} </strong>
              </div>
              <div class="col-6">
                Time: <strong>{{$filters.format_time(appointment.window_start)}}</strong>
              </div>
              <div class="col-12" v-if="appointment.payment_method">
                <payment-method-card :item="appointment.payment_method"/>
              </div>
              <div class="col-12">
                <span class="me-2">Instructions:</span>
                <strong> {{appointment.instructions || "..."}} </strong>
              </div>
              <div class="col-12">
                <hr>
                <h5 class="">Repair details</h5>
                <div class="d-flex align-items-center">
                  <img width="80" class="me-2" :src="absoluteUrl(appointment.device.image)" :alt="appointment.device.model" />
                  <div>
                    <div v-if="appointment.repair">
                      <h6 class="mb-1">{{appointment.repair.title}}</h6>
                    </div>
                    <p class="mb-0">{{appointment.device.model}}</p>
                  </div>
                </div>
              </div>
              <div class="col-12" v-if="appointment.store">
                <hr>
                <h5 class="">Store details</h5>
                <div class="">
                  <h6 class="mb-2" >{{ appointment.store.title}}</h6 >
                  <p class="mb-1" v-if="appointment.store.location">{{appointment.store.location.address}}, {{appointment.store.location.city}}, {{appointment.store.location.state}}</p>
                </div> 
              </div>
            </div>
          </div>
        </div>
        <div class="row gy-2 mb-4">
          <div v-if="appointment.address" class="col-lg-6">
            <address-card :item="appointment.address" />
          </div>
          <div class="col-lg-6">
            <div class="card">
              <div class="card-body">
                <div>
                  <h5 class="border-bottom pb-2">Pricing details </h5>
                  <div class="">
                    <div class="d-flex align-items-center justify-content-between">
                      <span class="mb-1" v-if="appointment.repair">{{appointment.repair.title}} cost</span>
                      <h6 class="mb-0">${{ $filters.money_format(appointment.service_cost)}}</h6>
                    </div>
                    <div class="mt-2 d-flex align-items-center justify-content-between">
                      <span class="mb-1">Convenience fee</span>
                      <h6 class="mb-0">${{ $filters.money_format(appointment.convenience_fee)}}</h6>
                    </div>
                  </div> 
                </div>
                <div class="bd-callout small p-2 mt-3 mb-0 bd-callout-info">
                  Repair option: <strong class="text-uppercase">{{appointment.repair_option.replace(/_/g, ' ')}}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5 class="mb-3">Invoice History</h5>
        <div class="card mb-5">
          <div class="card-body">
            <invoice-table :items="appointment.invoices" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else  />
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import PaymentMethodCard from '@/components/cards/PaymentMethodCard.vue'
import InvoiceTable from '@/components/tables/InvoiceTable.vue'
import AddressCard from '@/components/cards/AddressCard.vue'

export default {
  components: { 
    IsLoading,
    PaymentMethodCard,
    InvoiceTable,
    AddressCard
  },
  data(){
    return {
      isLoading: true,
      modalCreditCard: false,
      appointment: null
    }
  },
  watch: {
    '$route.params.appointId': function (id){
      if(id) this.fetchAppointment()
    },
  },
  methods:{
    updateItem(item){
      Object.assign(this.appointment, item)
    },
    fetchAppointment(){
      this.isLoading = true
      this.$http.get(`/appointments/${this.$route.params.appointId}/show`)
      .then((response) => {
          this.isLoading =  false
          if(response.data.success){
            this.appointment = response.data.data
          }else{
            this.$router.push({path: '/error-404'})
          }
      })
    }
  },
  created(){
    this.fetchAppointment()
  }
}

</script>

<style lang="scss">

</style>
